<template>
    <div>
        <span>
            * calculation after cap<br>
            ** calculation before cap<br><br>
            SKU : {{ DetailPricing.sku }}<br>
            Pricing Code : {{ DetailPricing.pricing_code }}<br>
            Pricing : {{ formatCurrency(DetailPricing.price) }}<br>
            Subtotal : {{ formatCurrency(DetailPricing.sub_total?DetailPricing.sub_total.toFixed(2):"0") }}<br><br>
        </span>
        <vs-table stripe border :sst="true" :data="priceStructure">
            <template slot="thead">
                <vs-th sort-key="">No</vs-th>
                <vs-th sort-key="promotion_sequence">Promotion Sequence</vs-th>
                <vs-th sort-key="promotion_type">Promotion Type</vs-th>
                <vs-th sort-key="promotion_code">Promotion Code</vs-th>
                <vs-th sort-key="discount_type">Discount Type</vs-th>
                <vs-th sort-key="amount">Amount *</vs-th>
                <vs-th sort-key="base">Base</vs-th>
                <vs-th sort-key="qty">Qty</vs-th>
                <vs-th sort-key="main">Main **</vs-th>
                <vs-th sort-key="add">Add **</vs-th>
                <vs-th sort-key="cap">Cap</vs-th>
                <vs-th sort-key="capped">Capped</vs-th>
                <vs-th sort-key="from">From</vs-th>
                <vs-th sort-key="to">To</vs-th>
            </template>
            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td v-if="tr.type=='calculation'">{{ tr.row }}</vs-td>
                    <vs-td v-if="tr.type=='calculation'">{{ tr.calculation.promotion_sequence }}</vs-td>
                    <vs-td v-if="tr.type=='calculation'">{{ tr.calculation.promotion_type }}</vs-td>
                    <vs-td v-if="tr.type=='calculation'">{{ tr.calculation.promotion_code }}</vs-td>
                    <vs-td v-if="tr.type=='calculation'">{{ tr.calculation.discount_string }}</vs-td>
                    <vs-td v-if="tr.type=='calculation'" align="right">{{ formatCurrency(tr.calculation.amount.toFixed(2)) }}</vs-td>
                    <vs-td v-if="tr.type=='calculation'" align="right"><small>({{ tr.calculation.base_string }})</small><br>{{ (tr.calculation.base_string == "value") ? formatCurrency(tr.calculation.base_amount.toFixed(2)) : tr.calculation.base_amount}}</vs-td>
                    <vs-td v-if="tr.type=='calculation'">{{ tr.calculation.qty }}</vs-td>
                    <vs-td v-if="tr.type=='calculation'" align="right">
                      <small>(rate)</small><br>
                      {{
                        (tr.calculation.discount_type==2?"IDR":"") +
                        formatCurrency(tr.calculation.main_rate.toFixed(2)) +
                        (tr.calculation.discount_type==1?"%":"") }}<br><br>
                      <small>(amount)</small><br>
                      {{ formatCurrency(tr.calculation.main.toFixed(2)) }}
                    </vs-td>
                    <vs-td v-if="tr.type=='calculation'" align="right">
                      <small>(rate)</small><br>
                      {{
                        (tr.calculation.discount_type==2?"IDR":"") +
                        formatCurrency(tr.calculation.add_rate.toFixed(2)) +
                        (tr.calculation.discount_type==1?"%":"") }}<br><br>
                      <small>(amount)</small><br>
                      {{ formatCurrency(tr.calculation.add.toFixed(2)) }}
                    </vs-td>
                    <vs-td v-if="tr.type=='calculation'" align="right">{{ formatCurrency(tr.calculation.cap.toFixed(2)) }}</vs-td>
                    <vs-td v-if="tr.type=='calculation'">{{ tr.calculation.capped }}</vs-td>
                    <vs-td v-if="tr.type=='calculation'" align="right">{{ (tr.calculation.base_string == "value") ? formatCurrency(tr.calculation.from.toFixed(2)) : tr.calculation.from }}</vs-td>
                    <vs-td v-if="tr.type=='calculation'" align="right">{{ (tr.calculation.base_string == "value") ? formatCurrency(tr.calculation.to.toFixed(2)) : tr.calculation.to }}</vs-td>
                    <!-- baris subtotal -->
                    <vs-td v-if="tr.type!='calculation'" colspan="4"></vs-td>
                    <vs-td v-if="tr.tfoot==true" colspan="7"></vs-td>
                    <vs-td v-if="tr.type!='calculation' && tr.tfoot!=true" ><b>{{ tr.type }}</b></vs-td>
                    <vs-td v-if="tr.type!='calculation' && tr.tfoot!=true" align="right"><b>{{ formatCurrency(tr.calculation.order_line_after_discount?tr.calculation.order_line_after_discount.toFixed(2):0) }}</b></vs-td>
                    <vs-td v-if="tr.type!='calculation' && tr.tfoot!=true" colspan="7"></vs-td>
                    <vs-td v-if="tr.tfoot==true"><b>{{ tr.type }}</b></vs-td>
                    <vs-td v-if="tr.tfoot==true" align="right" colspan="2"><b>{{ formatCurrency(tr.calculation.order_line_after_discount?tr.calculation.order_line_after_discount.toFixed(2):0) }}</b></vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </div>
</template>

<script>
import FormInput from "./form_input.vue";
import Datepicker from "vuejs-datepicker";
import AddressComponent from "@/components/master/Address";
export default {
    components: {
        Datepicker,
        FormInput,
        AddressComponent,
    },
    props: ["DetailDataDiscount","DetailPricing"],

    data() {
        return {
            title: "",
            priceStructure: []
        };
    },
    methods: {

        formatCurrency(value) {
            if (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }else{
                return "0"
            }
        },

    },
    filters: {
        formatCurrency(value) {
            if (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
    },
    mounted() {
        // console.log("DetailDataDiscount",this.DetailDataDiscount)
        this.priceStructure = []
        let subtotalNo = 1
        let subtotalString = ""
        this.DetailDataDiscount.forEach((calculation, index) => {
            this.priceStructure.push({ row:index+1, type: 'calculation', calculation });

            if (index + 1 < this.DetailDataDiscount.length && calculation.promotion_sequence != this.DetailDataDiscount[index + 1].promotion_sequence) {
                subtotalString = "subtotal "+subtotalNo
                this.priceStructure.push({ type: subtotalString,  calculation});
                subtotalNo++
            }

            if (index == this.DetailDataDiscount.length-1) {
                subtotalString = "subtotal "+subtotalNo
                this.priceStructure.push({ type: subtotalString,  calculation});
                subtotalNo++
            }
        });
        // if (this.DetailDataDiscount&&this.DetailDataDiscount.length>0) {
            // rounding & nett
            this.priceStructure.push({ type: "rounding",  calculation:{
            "order_line_after_discount": this.DetailPricing.rounding
            },tfoot:true});
            // this.priceStructure.push({ type: "nett",  calculation:{
            //   "order_line_after_discount": this.DetailPricing.nett
            // }});

            // grossup
            this.priceStructure.push({ type: "grossup",  calculation:{
            "order_line_after_discount": this.DetailPricing.gross_up
            },tfoot:true});

            // dpp
            this.priceStructure.push({ type: "dpp",  calculation:{
            "order_line_after_discount": this.DetailPricing.dpp
            },tfoot:true});

            // tax
            this.priceStructure.push({ type: "tax",  calculation:{
            "order_line_after_discount": this.DetailPricing.tax
            },tfoot:true});

            // grand_total
            this.priceStructure.push({ type: "grand total",  calculation:{
            "order_line_after_discount": this.DetailPricing.grand_total
            },tfoot:true});

            // console.log("DetailPricing ", this.DetailPricing)
            // console.log("priceStructure ", this.priceStructure)
        // }

    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>

<style scoped>
.badge.badge-success {
    background-color: #28c76f;
}

.badge {
    font-weight: 400;
    color: #fff;
    background-color: #7367f0;
}

.badge-success {
    color: #fff;
    background-color: #28c76f;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.core-enter-active {
    transition: all 0.3s ease;
}

.core-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.core {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px;
}

.vertical-divider {
    border-left: 1px solid #7367f0;
    min-height: 800px;
    padding: 5px;
}

.vs-table--thead {
    z-index: -1 !important;
    display: none;
}

.table-expend:hover,
.table-expend-combo:hover td.combo-hover {
    background: #e9e9e9;
    cursor: pointer;
}

.table-expend:active,
.table-expend-combo td.combo-hover:active {
    background: #e7e5e5;
}
</style>
